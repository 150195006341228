import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Жилищные споры | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ При решении жилищных вопросов в особенности необходимо грамотное юридическое сопровождение, ведь в таких случаях неправильно составленный иск, не поданное вовремя заявление могут привести к тому, что человек теряет право на жилье и оказывается на улице." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/zhilishchnye-spory'
				name="Жилищные споры | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ При решении жилищных вопросов в особенности необходимо грамотное юридическое сопровождение, ведь в таких случаях неправильно составленный иск, не поданное вовремя заявление могут привести к тому, что человек теряет право на жилье и оказывается на улице. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Жилищные споры" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Жилищные споры"}
			
				html={

				`

				<p class="TextText">
				Часть 1 статьи 40 Конституции РФ гласит: "Каждый имеет право на жилище. Никто не может быть произвольно лишен жилища.". К сожалению, в реальности часто мы сталкиваемся с тем, что жилищные права граждан грубо нарушаются. Причем нарушителями таких прав могут выступать как другие граждане, так и государственные (муниципальные) органы, которые призваны, казалось бы, стоять на защите прав и интересов граждан.  Очевидно, что жилье необходимо каждому. Поэтому жилищные споры являются достаточно сложным и болезненным вопросом как для собственников жилых помещений, так и для других лиц, владеющих и пользующихся жилыми помещениями на иных основаниях.
				</p>

				<p class="TextText">
				Для жилищных споров характерной особенностью является то, что зачастую сторонами по делу бывают бывшие супруги, члены семьи, родственники и друзья, что накладывает свой моральный отпечаток на ведение дела. Другая сложность данной категории дел состоит в том, что человеку, отстаивающему свои жилищные права, приходится сталкиваться с мощным противодействием со стороны административных органов (жилищных органов, муниципалитета, администрации учреждений и предприятий). И причиной такого противодействия чаще всего служит не просто бюрократическая волокита, а прямая материальная заинтересованность должностных лиц в том или ином результате разрешения Вашего жилищного вопроса.
				</p>

				<p class="TextText">
				При решении жилищных вопросов в особенности необходимо грамотное юридическое сопровождение, ведь в таких случаях неправильно составленный иск, не поданное вовремя заявление могут привести к тому, что человек теряет право на жилье и оказывается на улице.
				</p>

				<p class="TextText">
				Юридическая помощь требуется для решения самых различных проблем в жилищной сфере: начиная от приобретения жилплощади (купля – продажа, дарение, наследование) и заканчивая ее разделом между членами семьи, выселением, признанием утраты права пользования жилым помещением и т.д.
				</p>

				<p class="TextText">
				Близко примыкающими к категории жилищных дел являются иные дела, связанные с недвижимостью: сделки с земельными участками, дачами, участие в делах СНТ, применение дачной амнистии, споры с администрацией сельского округа и т.п.
				</p>

				<p class="TextText">
					<b>Мы готовы оказать следующие услуги по данной категории дел:</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					консультирование по жилищным и иным вопросам, связанным с недвижимостью
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					правовой анализ договоров по приобретению/отчуждению недвижимости, документов, удостоверяющих права на недвижимость (купля-продажа, дарение, наследование, долевое участие в строительстве и т.д.)
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					вселение в жилое помещение; выселение из жилого помещения
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					признание гражданина утратившим (не приобретшим) права пользования
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					иски о нечинении препятствий в пользовании недвижимостью (квартирой, дачей, земельным участком)
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					переход права собственности на недвижимость в порядке наследования
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					предоставление жилых помещений по договору социального найма
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					признание обмена жилыми помещениями, предоставленными по договорам социального найма, недействительным
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					изменение договора социального найма жилого помещения
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					раздел недвижимого имущества, определение и выделение доли
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					определение порядка пользования недвижимым имуществом
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					рента (в т.ч. расторжение договора ренты, признание недействительным договора ренты
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					дела, связанные с долевым строительством жилья
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					государственная регистрация прав (в т.ч. обжалование отказа в осуществлении регистрационных действий, принудительная регистрация)
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					признание права собственности на недвижимое имущество в связи с приобретательной давностью
				</p>


				
				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
